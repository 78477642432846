<!-- eslint-disable no-fallthrough -->
<script setup lang="ts">
  import { useHead, useSeoMeta } from '@unhead/vue'
  import { ref, computed, toRefs, watch } from 'vue'
  import { useDeckStore } from '@/store'
  import { onBeforeRouteLeave, useRouter, useRoute } from 'vue-router'
  import { onKeyDown, useSwipe } from '@vueuse/core'
  import PhotoView from './PhotoView.vue'
  import VideoView from './VideoView.vue'
  import WebStoryView from './WebStoryView.vue'
  import DocumentView from './DocumentView.vue'
  import type { Component } from 'vue'
  import type TextDocument from '@/store/parser/TextDocument'

  const props = defineProps({
    docId: {
      type: String,
      required: true
    },
    columnId: {
      type: String,
      default: null
    }
  })

  const { docId, columnId } = toRefs(props)
  const deckStore = useDeckStore()
  const {
    getColumnById, getDocumentById, getNextDocumentIdInColById, getPreviousDocumentIdInColById,
    refreshColumn
  } = deckStore

  const router = useRouter()
  const route = useRoute()

  const doc = computed(() => getDocumentById(docId.value))
  const column = computed(() =>
    columnId.value ? getColumnById(columnId.value) : null
  )

  const searchTerms = computed(() => {
    if (!column.value || !column.value.params.query) return []

    const query = column.value.params.query
    // Regex pour extraire les termes de recherche
    const searchTermsRegex =
      /(?:"[^"]*"|[^:\s()]+)(?:\s+(?:AND|OR)\s+(?:"[^"]*"|[^:\s()]+))*|(?::[^:\s()]+)/g
    const matches = query.match(searchTermsRegex) || []

    return matches
      .map(term => {
        // Si le terme contient un double point, on ne garde que la partie après
        if (term.includes(':')) {
          return term.split(':')[1]
        }
        return term
      })
      .filter(term => term && !term.startsWith(':')) // Ignorer les préfixes
      .map(term => term.replace(/^"|"$/g, '')) // Enlever les guillemets
      .filter(term => term && !['AND', 'OR'].includes(term)) // Ignorer les opérateurs
      .filter(term => !/^[()]+$/.test(term)) // Ignorer les parenthèses seules
  })

  useHead({
    title: doc.value?.headline,
    meta: [
      {
        name: 'description',
        content:
          doc.value &&
          ['text', 'multimedia', 'factcheck'].includes(doc.value.type) &&
          (doc.value as TextDocument).paragraphs[0]
      },
      {
        name: 'site',
        content: import.meta.env.VITE_APP_TITLE
      },
      {
        name: 'author',
        content: doc.value?.creator
      }
    ]
  })

  useSeoMeta({
    ogTitle: doc.value?.headline,
    ogType: 'article',
    ogUrl: window.location.href
  })

  const docComponent = computed(() => {
    if (!doc.value) return null
    switch (doc.value.type) {
      case 'photo':
      case 'graphic':
        return PhotoView
      case 'video':
        return VideoView
      case 'webstory':
        return WebStoryView
      case 'multimedia':
      case 'text':
      case 'factcheck':
      default:
        return DocumentView
    }
  })

  function close() {
    router.push({
      name: 'deck'
    })
  }

  function goTo(
    columnId: string,
    docId: string,
    docType: string,
    direction: 'left' | 'right'
  ) {
    router.push({
      name: 'document',
      params: { docId },
      query: { legend: route.query.legend },
      state: { columnId, docType, direction }
    })
  }

  // Hook nécessaire pour assurer la transition de fermeture
  const showDoc = ref(true)
  onBeforeRouteLeave((to, from, next) => {
    if (to.name === 'deck') {
      showDoc.value = false
      setTimeout(next, 300)
    } else {
      next()
    }
  })

  async function newerDocument() {
    if (!columnId.value) return
    const newerDocId = getNextDocumentIdInColById(columnId.value, docId.value)
    if (newerDocId) {
      const newerDoc = getDocumentById(newerDocId)
      goTo(columnId.value, newerDocId, newerDoc.type, 'left')
      return
    } else {
      const newDocsFound = await refreshColumn(columnId.value, 'newer')
      if (newDocsFound) {
        newerDocument()
        return
      }
    }
    close()
  }

  async function olderDocument() {
    if (!columnId.value) return
    const olderDocId = getPreviousDocumentIdInColById(columnId.value, docId.value)
    if (olderDocId) {
      const olderDoc = getDocumentById(olderDocId)
      goTo(columnId.value, olderDocId, olderDoc.type, 'right')
      return
    } else {
      const newDocsFound = await refreshColumn(columnId.value, 'older')
      if (newDocsFound) {
        olderDocument()
        return
      }
    }
    close()
  }

  // Use arrow keys to switch between documents
  onKeyDown('ArrowDown', e => {
    olderDocument()
    e.preventDefault()
  })
  onKeyDown('ArrowUp', e => {
    newerDocument()
    e.preventDefault()
  })
  onKeyDown('Escape', e => {
    close()
    e.preventDefault()
  })

  // Use swipe to switch between documents
  const docEl = ref<HTMLElement>()
  const { isSwiping, direction: swipeDirection } = useSwipe(docEl)
  watch(isSwiping, val => {
    if (val) {
      if (swipeDirection.value === 'left') {
        olderDocument()
      } else if (swipeDirection.value === 'right') {
        newerDocument()
      }
    }
  })
</script>

<template>
  <Component
    v-show="showDoc"
    :is="docComponent as Component"
    :key="doc.id"
    :doc="doc"
    :lang="doc.lang"
    :dir="doc.lang === 'ar' ? 'rtl' : 'ltr'"
    :search-terms="searchTerms"
    ref="docEl"
    class="absolute top-0 right-0 bottom-0 left-0 z-3 print:relative"
    @close="close"
  >
  </Component>
</template>
