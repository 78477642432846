<script lang="ts" setup>
  import { SUPPORT_LOCALES } from '@/plugins/i18n'
  import { setPreferredLanguage } from '@/plugins/i18n'
  import type { Locale } from '@/types'
  import { track } from '@vercel/analytics'
  import { preferredLocale } from '@/plugins/i18n'

  async function setPreferredLanguageHandler(lang: Locale) {
    await setPreferredLanguage(lang)
    track('change_language', { lang })
  }
</script>

<template>
  <details>
    <summary>{{ preferredLocale }}</summary>
    <ul class="bg-base-100 rounded-t-none p-2 z-3">
      <li
        v-for="lang in SUPPORT_LOCALES"
        v-show="lang !== preferredLocale"
        :key="lang"
        href="#"
        @click.prevent="setPreferredLanguageHandler(lang)"
      >
        <a>{{ lang }}</a>
      </li>
    </ul>
  </details>
</template>
